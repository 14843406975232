<template>
  <keep-alive>
    <v-container fluid class="d-flex flex-column h-100 pa-0 min-height-inherit">
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit">
        <admin-left-section class="hidden-md-and-down" v-if="type == 'Admin'" />
        <need-map-contents-section />
        <need-map-right-section class="hidden-sm-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import NeedMapContentsSection from "./NeedMapContentsSection.vue";
import NeedMapRightSection from "./NeedMapRightSection.vue";
import AdminLeftSection from "../admin/home/AdminHomeSideMenu";
import { mapState } from "vuex";
export default {
  components: {
    NeedMapRightSection,
    NeedMapContentsSection,
    AdminLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: []
    };
  },
  computed: {
    ...mapState("auth", {
      type: "type"
    })
  }
};
</script>
<style scoped></style>
<style>
html {
  overflow-y: auto !important;
}
</style>
