var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feed-body"},[_c('div',{staticClass:"feed-contents"},[(_vm.center)?_c('GmapMap',{staticClass:"map",attrs:{"center":_vm.center,"zoom":13,"map-type-id":"terrain","options":_vm.$vuetify.breakpoint.xs
          ? {
              fullscreenControl: false
            }
          : null}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"icon":m.icon},on:{"click":function($event){return _vm.onClickedMarker(m, index)}}},[_c('GmapInfoWindow',{attrs:{"opened":m.ifw,"options":{
            pixelOffset: { width: 0, height: 0 },
            content: m.template
          }},on:{"update:opened":function($event){return _vm.$set(m, "ifw", $event)},"closeclick":function($event){m.ifw = false}}})],1)}),1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-btn',{staticStyle:{"position":"absolute","top":"10px","right":"10px","padding":"0","min-width":"50px","height":"40px"},attrs:{"tile":""},on:{"click":_vm.openRightSection}},[_c('v-icon',{staticStyle:{"margin-top":"2.5px"}},[_vm._v("mdi-menu")])],1):_vm._e()],1),(_vm.rightSectionDialog)?_c('v-dialog',{model:{value:(_vm.rightSectionDialog),callback:function ($$v) {_vm.rightSectionDialog=$$v},expression:"rightSectionDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4 pb-8"},[_vm._l((_vm.needs),function(need){return _c('agency-need-card',{key:need._id,staticClass:"mt-4",attrs:{"need":need,"hideButtons":true,"onClicked":_vm.onSelected}})}),(!_vm.needs.length)?_c('div',{staticClass:"mt-10 mx-10"},[_vm._v(" No needs found. "),(_vm.profileType == 'Agency')?_c('div',[_vm._v(" Please create new needs. ")]):_vm._e()]):_vm._e()],2)],1)],1):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }